a, body, div, header, html, p, span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

body, html{
  height: 100%;
}

body {
  min-width: 1024px;
  font: 12px/1.5 "Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #353535;
  background: #fff;
}

header {
  display: block;
}

a {
  text-decoration: none;
  color: #353535;
}

header {
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f1f1f1;
}

.toolbar {
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f1f1f1;
}

.toolbar .toolbar-container {
  display: flex;
  align-content: center;
} 

.toolbar .toolbar-container .logo {
  margin: 8px 0 0 25px;
  background: url(images/sprite/logo.png) no-repeat;
  /* position: fixed; */
  height: 60px;
  background-size: contain;
  width: 100px;
  /* background-position: center; */
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent;
}

.toolbar .toolbar-container .toolbar-title {
  font-size: 20px;
  font-weight: 700;
  /* bolder; */
  height: 60px;
  line-height: 60px;
  cursor: default;
}

.fl {
  float: left;
}

.fr{
  float: right;
}

.toolbar .toolbar-container .spacer {
  flex : 1;
}

.toolbar .toolbar-container .login-info {
  height: 32px;
  margin: 14px 15px 0 0;
  line-height: 32px;
}

.login-info .btn-logout {
  margin-left: 10px;
  padding: 0 8px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  line-height: 28px;
  background: #f1f1f1;
  color: #666;
  cursor: pointer;
}

.menubar {
  height: 57px;
  line-height: 57px;
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;
}

.menubar .menu-title {
  position: relative;
  width: 240px;
  height: 100%;
}

.menubar .menu-title a {
  display: block;
  padding-left: 35px;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
  color: #1e1e1e;
}

.menubar .menu-title a .sprite-more {
  display: block;
  margin: 22px 15px 0 0;
}

.menubar .menu-title .menu-list {
  position: absolute;
  left: -240px;
  top: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0,0,0,.8);
  transition: .15s linear left;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding-inline-start: 0;
}

.menubar .menu-title .menu-list.menu-list-expanded {
  left: 0;
  height: auto;
}

/* 
.menubar .menu-title .menu-list {
  margin: 0;
  padding-inline-start: 0;
} */

.menubar .menu-title .menu-list .menu-home a {
  display: block;
  border-bottom: 1px solid #616161;
  color: #fff;
}

.menubar .menu-title .menu-list .menu-home a:hover {
  background: rgba(0,0,0,.9);
}

.menubar .menu-title .menu-list .menu-item a {
  display: block;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  color: #fff;
}

.menubar .menu-title .menu-list .menu-item a:hover {
  background: rgba(0,0,0,.9);
}

.menubar .menu-title .menu-list .menu-item a span {
  margin: 16px 20px 0 0;
}

.content {
  /* position: fixed; */
  height: calc(100% - 120px);
  width: 100%;
  /* background: rgba(218, 214, 214, 0.8);
  z-index: 100; */
}

.content .menu-list {
  padding: 70px 0 0 160px;
}

.content .menu-list li {
  float: left;
  width: 220px;
  height: 175px;
  margin: 0 100px 50px 0;
  text-align: center;
  font-size: 12px;
}

.content .menu-list li .menu-icon {
  display: inline-block;
}

.content .menu-list li .menu-icon:hover { 
  opacity: .9;
}

.content .menu-list li .menu-name {
  display: block;
  margin: 18px 0 6px;
}

.content .menu-list li .menu-name a {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  color: #282828;
}

.content .menu-list li .menu-name a:hover {
  text-decoration: underline;
}

.content .sub-page{
  position:relative;
  z-index:10
}

.content .checkListWrapper{
  padding:30px;
  background-color:#f8f8f8
}
.content .checkListWrapper .checkList{
  position:relative
}

.sprite-fscp-s, .sprite-others-s, .sprite-more ,.sprite-fscp, .sprite-others {
  background: url(images/sprite/sprite_01.png) no-repeat;
}

.sprite-more {
  background-position: 0 -3499px;
  height: 13px;
  width: 18px;
}

.sprite-more.more-active, .sprite-more:active {
  background-position: 0 -3481px;
}

.sprite-fscp-s {
  /* background-position: 0 -4099px; */
  background-position: 0px -4783px; /*cakrab*/
  height: 26px;
  width: 26px;
}

.sprite-others-s {
  background-position: 0 -509px;
  height: 26px;
  width: 26px;
}

.sprite-fscp {
  background-position: 0 -4814px; /*cakrab */
  height: 81px;
  width: 81px;
}

.sprite-others {
  background-position: 0 -540px;
  height: 81px;
  width: 81px;
}
/*cakrab*/
.fscp-detail-wrapper {
  display:flex;
  justify-content: left; 
}
/*cakrab*/


/*cakrab*/
.fscp-detail-wrapper .fscp-detail-btn {
  border-radius: 5px;
  width: 40px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
}

/*cakrab*/
.fscp-detail-attach-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
/*cakrab*/

/*cakrab*/
.fscp-detail-attach-wrapper .fscp-detail-attach-btn {
  border-radius: 5px;
  width: 50px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
}

.fscp-detail-attach-wrapper .fscp-detail-attach-btn-disabled {
  border-radius: 5px;
  width: 50px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
  opacity: 0.8;
}
/*cakrab*/
/*cakrab*/
/* .fscp-detail-attach-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
} */
/*cakrab*/


.content .fscp-wrapper, .content .fscpdetail-wrapper{
  position: relative;
  margin: 16px;
  padding: 0 10px 10px 10px;
  height: calc(100% - 32px);
  width: calc(100% - 32px); 
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: -1px 1px 4px #e4e4e4;
  box-sizing: border-box;
}

.content .fscp-wrapper #title, .content .fscpdetail-wrapper #title {
  text-align: left;
  font-weight: 700;
  /* font-size: 14px; */ /*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  color: #000;/*cakrab*/
}

.content .fscp-wrapper .fscp-search {
  /*position: relative;
  padding: 16px 0px 16px 0px;
  height: 72px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: -1px 1px 4px #e4e4e4;
  box-sizing: border-box;   
  display:flex;
  margin-left: 2px;*/
  /*cakrab*/
  display: flex;/*cakrab*/
  padding: 3px;/*cakrab*/
  background: #e5e5e5;/*cakrab*/
  border-radius: 2px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper, .content .fscpdetail-wrapper .fscpdetail-btn-wrapper {
  /*margin-left: 25px;*//*cakrab*/
  justify-content:flex-end;
  display:flex;
  margin-left: 20px;/*cakrab*/
  margin-top: 2px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn {
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color: #3d6ed1;/*cakrab*/
  height: 28px;/*cakrab*/
  width: 100px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn-report {
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color: #3d6ed1;/*cakrab*/
  height: 28px;/*cakrab*/
  width: 200px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn-disabled{
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color:#3d6ed1;/*cakrab*/
  opacity: 0.6;
  height: 28px;/*cakrab*/
  width: 100px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscpdetail-wrapper .fscpdetail-btn-wrapper .fscpdetail-btn {
  border-radius: 5px;
  width: 76px;
  height: 28px;
  margin: 10px 15px 0 15px;
  color: #fff;/*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
}

.content .fscpdetail-wrapper .fscpdetail-btn-wrapper .fscpdetail-btn-disabled {
  border-radius: 5px;
  width: 76px;
  height: 28px;
  margin: 10px 15px 0 15px;
  color: #fff;/*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  opacity: 0.6;
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
}

.content .fscp-wrapper .fscp-summary {
  margin: 12px 0 12px 0;
  position: relative;
  height: calc(100% - 16px);
}

.content .fscp-wrapper .fscp-summary {
  width: 100%;
  border-radius: 3px;
}

.content .fscp-wrapper .fscp-summary a {
  text-decoration: none;
}

.content .fscp-wrapper .fscp-summary a:hover {
  text-decoration: none;
  border-bottom: 1px solid blue;
}

.content .fscpdetail-wrapper .fscpdetail-params-container {
  display: flex;
}

.content .fscpdetail-wrapper .fscpdetail-params-container div{
  flex: 1;
}

.content .fscpdetail-wrapper .fscpdetail-params-container {
  position: relative;
  padding: 5px 0px 10px 0px;
  height: 70px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: -1px 1px 4px #e4e4e4;
  box-sizing: border-box; 
  margin-left: 2px;
}

.content .fscpdetail-wrapper .fscpdetail-params-container .fscpdetail-params {
  margin-left: 10px;
  font-size:14px;
  display: flex;
  flex-direction: column;
}

.content .fscp-detail {
  margin-left: 16px;
  width: calc(100% - 32px);
  border: 0;
}


.fscp-history-wrapper {
  display:flex;
  justify-content: center; 
}

.fscp-history-wrapper .fscp-history-btn {
  border-radius: 5px;
  width: 76px;
  height: 28px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
}

/*cakrab*/
.fscp-showhistory-btn {
  display: block; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

.fscp-updstat {
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

/*cakrab*/
.fscp-updstat-btn {
  display: block; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

/*cakrab*/
.fscp-updstat-btn-disabled {
  display: none; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  opacity: 0.6;
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

.fscp-updstat-btn-wrapper, .fscp-createalert-wrapper {
  display:flex;
  justify-content: center;
}

.fscp-updstat-btn-wrapper .fscp-updstat-btn, .fscp-createalert-wrapper .fscp-createalert-btn {
  margin: 0 10px 0 10px;
  border-radius: 5px;
  width: 72px;
  height: 28px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
}

.attachment-logos {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  height: 20px;
   width: 25px;
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent; 
}

#content-editable{
  -moz-user-select: text;
  -moz-user-modify: read-write;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
  }

  .font-blue{
    color: blue
  }

.btn-focus{
  background-color: #1f345c!important;
}

.btn-neutral{
  background: rgb(241 241 241)!important;
  color: black!important;
}